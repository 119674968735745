/*
-----------------------------------------------------------------------
  Header CSS
-----------------------------------------------------------------------
*/

.header-area {
  padding: 50px 0;

  @media #{$lg-device} {
    .container {
      max-width: none;
      width: 100%;
    }
  }

  @media #{$md-device} {
    padding: 53px 0;
  }

  @media #{$sm-device} {
    padding: 25px 0;
  }

  // Sticky Header
  &.sticky-header {
    transition: 0.4s;

    &.sticky {
      background-color: rgba($black, 0.65);
      box-shadow: none;
      border: 0 !important;
      position: fixed;
      left: 0;
      top: 0;
      padding: 25px 0;
      width: 100%;
      z-index: 99;

      .header-logo-area {
        .logo-main {
          display: none;
        }
        .logo-light {
          display: block;
        }
      }

      .main-menu {
        & > li {
          &:hover {
            & > a {
              background-color: $black-111;
              color: $white;
              &:after {
                opacity: 1;
              }
            }
          }

          & > a {
            color: $white;
            &:after {
              background-color: $black-111;
              content: "";
              height: 100%;
              opacity: 0;
              position: absolute;
              right: -14px;
              top: 0;
              width: 14px;
              @include transition(0.3s);
            }
            &:hover {
              background-color: $black-111;
              &:after {
                opacity: 1;
              }
            }

            &:before {
              color: $white;
            }
          }
        }
      }
      .header-action-area {
        button,
        a {
          color: $white;
          &:hover {
            background-color: $black-111;
          }

          &.btn-cart {
            & + .header-shopping-cart {
              top: calc(100% + 45px);
              .widget-shopping-cart-content {
                .cart-list-product {
                  .mini-cart-item {
                    a {
                      background-color: transparent;
                      &:hover {
                        background-color: transparent;
                      }
                    }
                  }
                }
              }
              &:hover {
                opacity: 1;
                top: calc(100% + 25px);
                visibility: visible;
              }
            }
            &:hover {
              & + .header-shopping-cart {
                top: calc(100% + 25px);
              }
            }
          }

          &.btn-menu {
            background-color: transparent;
            &:hover {
              background-color: transparent;
            }
          }
        }

        .btn-search-content {
          bottom: -145px;
          &.show {
            bottom: -125px;
          }
        }

        .btn-menu {
          span {
            background-color: $heading-color;
          }
        }
      }
    }
  }

  // Transparent Header Style
  &.transparent:not(.sticky) {
    color: $white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9;

    .header-logo-area {
      .logo {
        &-main {
          display: none;
        }

        &-light {
          display: block;
        }
      }
    }

    .main-menu {
      & > li {
        &:hover {
          & > a {
            background-color: $black-111;
            color: $white;
            &:after {
              opacity: 1;
            }
          }
        }

        & > a {
          color: $white;
          &:after {
            background-color: $black-111;
            content: "";
            height: 100%;
            opacity: 0;
            position: absolute;
            right: -14px;
            top: 0;
            width: 14px;
            @include transition(0.3s);
          }
          &:hover {
            background-color: $black-111;
            &:after {
              opacity: 1;
            }
          }

          &:before {
            color: $white;
          }
        }
      }
    }

    .header-action-area {
      button,
      a {
        color: #666666;
        &:hover {
          background-color: $black-111;
        }
      }

      .btn-menu {
        background-color: transparent;
        span {
          background-color: #666666;
        }
        &:hover {
          background-color: transparent;
          span {
            background-color: $theme-color;
          }
        }
      }
    }
  }

  // Header Default
  &.header-default {
    background-color: #ffffff;
    padding: 40px 0 35px;
    position: relative;
    z-index: 999;
    @media #{$md-device} {
      padding: 20px 0 20px;
    }
    @media #{$sm-device} {
      padding: 30px 0 20px;
    }
    .container-fluid {
      padding-left: 60px;
      padding-right: 60px;
      @media #{$xxl-device} {
        padding-left: 15px;
        padding-right: 15px;
      }
      @media #{$lg-device} {
        padding-left: 15px;
        padding-right: 15px;
      }
      @media #{$xs-device} {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .header-search {
      width: 100px;
      text-align: right;
      // Responsive
      @media #{$xxs-device} {
        width: auto;
      }
      @media #{$sm-device} {
      }
      .header-search-toggle {
        background-color: transparent;
        border: 0;
        color: $heading-color;
        font-size: 20px;
        line-height: 1;
        margin-right: 26.3px;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          color: $black;
        }
      }
    }

    .header-logo-area {
      max-width: none;
      @media #{$md-device} {
        max-width: 140px;
      }
      @media #{$sm-device} {
        max-width: 110px;
      }
    }

    .header-navigation-area {
      .main-menu {
        & > li {
          margin-right: 0;
          &:first-child {
            & > a {
              padding-left: 0;
            }
          }
          &:last-child {
            margin-right: 0;
            & > a {
              padding-right: 0;
            }
          }
          & > a {
            padding: 7px 17.55px;
          }
          &.has-submenu {
            & > a {
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }

    .header-action-area {
      @media #{$sm-device} {
        margin-top: 2px;
        position: relative;
      }
      button,
      a {
        &.btn-menu {
        }
      }
    }

    &.sticky-header {
      &.sticky {
        background-color:#ffffff !important;
        @media #{$sm-device} {
          padding: 22px 0 27px;
        }
        @media #{$xs-device} {
          padding: 17px 0 22px;
        }
        .header-align {
          .header-social {
            margin-top: 14px;
          }
          .header-logo-area {
            img {
              max-width: 153.7px;
              @media #{$sm-device} {
                max-width: 100%;
              }
            }
          }
          .header-navigation-area {
            .main-menu {
              & > li {
                & > a {
                  color: $white;
                  &:hover {
                    background-color: transparent;
                    color: $theme-color;
                  }
                }
                &:hover {
                  a {
                    background-color: transparent;
                    &:after {
                      display: none;
                    }
                  }
                }
                &.active {
                  & > a {
                    color: $theme-color;
                  }
                }
                &.has-submenu {
                  & > a {
                    &:before {
                      display: none;
                    }
                  }
                  & > .submenu-nav {
                    margin-top: 24px;
                    @media #{$xlm-device} {
                      margin-top: 32px;
                    }
                    &:before {
                      height: 44px;
                    }
                  }
                }
              }
            }
          }
          .header-action-area {
            margin-top: 11px;
            @media #{$sm-device} {
              margin-top: 0;
            }
            .btn-menu {
              span {
                background-color: $black-999;
              }
              &:hover {
                span {
                  background-color: $black;
                }
              }
            }
          }
        }
      }
    }

    &.transparent {
      background-color: transparent;
      &.sticky-header {
        &.sticky {
          background-color: rgba($black, 0.65);
        }
      }
      .header-align {
        .header-navigation-area {
          .main-menu {
            & > li {
              & > a {
                color: $white;
                &:hover {
                  background-color: transparent;
                  color: $theme-color;
                }
              }
              &:hover {
                a {
                  background-color: transparent;
                  &:after {
                    display: none;
                  }
                }
              }
              &.active {
                & > a {
                  color: $theme-color;
                }
              }
              &.has-submenu {
                & > a {
                  &:before {
                    display: none;
                  }
                }
              }
            }
          }
        }
        .header-action-area {
        }
      }
    }
  }
}
.hb-border {
  border-bottom: 1px solid #ddd;
}

// Logo Style
.header-logo-area {
  max-width: 148px;
  position: relative;
  top: 1px;

  .logo {
    &-light {
      display: none;
    }
  }
}

// Header Action Style
.header-action-area {
  width: 100px;
  // Responsive
  @media #{$xxs-device} {
    width: auto;
  }
  button,
  a {
    color: $body-color;
    font-size: 14px;
    line-height: 1;
    margin-left: 15px;
    vertical-align: middle;

    &:first-child {
      margin-left: 0;
    }

    &.btn-menu {
      background-color: transparent;
      border: none;
      box-shadow: none;
      height: 20px;
      margin-top: 0;
      position: relative;
      width: 23px;
      span {
        background-color: $heading-color;
        border-radius: 2px;
        display: block;
        height: 2px;
        position: absolute;
        right: 0;
        top: 45%;
        width: 100%;
        @include transition(0.3s);

        &:first-child {
          top: 0;
        }

        &:last-child {
          bottom: 1px;
          top: auto;
        }
      }

      &:hover {
        span {
          background-color: $black;
          &:first-child,
          &:last-child {
            width: 100%;
          }
        }
      }
    }
  }
  .menu-text {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 600;
    text-transform: uppercase;
    color: #000;
    margin-left: 12px;
    letter-spacing: 1px;
    // Responsive
    @media #{$xs-device} {
      display: none;
    }
  }
}

// Header Top Style
.header-top {
  background-color: $theme-color;
  padding: 12px 0 14px;
  @media #{$md-device} {
    display: none;
  }

  .header-top-nav {
    a {
      color: #bdbfc3;
      font-size: 13px;
      margin-left: 36.43px;
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        color: $white;
      }
    }
  }

  .header-top-info {
    margin-bottom: 0;
    padding-top: 2px;
    li {
      color: #bdbfc3;
      font-size: 13px;
      float: left;
      margin-left: 43.66px;
      i {
        width: 17px;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }

  .header-icons {
    a {
      color: $white;
      &:hover {
        color: $theme-color;
      }
    }
  }

  &.inline-style {
    .header-left {
      float: left;
    }
    .header-right {
      float: right;
    }
    .container {
      @media #{$lg-device} {
        max-width: none;
      }
    }
  }

  & + .header-area {
    padding: 25px 0;
  }
}

// Fixed To Header
.header-area.fixed-top.sticky-header {
  background-color: transparent;
  box-shadow: none;
  padding: 50px 0;
}

// Off Canvas Style CSS

.off-canvas-wrapper {
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  height: 100vh;
  width: 100%;
  z-index: 9999;

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
    right: 0;

    .off-canvas-inner {
      transform: none;
      .off-canvas-content {
        transform: none;
        transition-delay: 0s;
      }
    }

    .btn-close {
      display: block;
    }

    .off-canvas-overlay {
      opacity: 1;
      visibility: visible;
    }
  }

  .off-canvas-header {
    display: flex;
    justify-content: right;
    padding: 30px 0;

    .logo-area {
      display: none;
      max-width: 100px;
    }

    .close-action {
      display: block;
      width: 100%;
      .btn-close {
        background-image: none;
        color: $white;
        font-size: 40px;
        float: right;
        opacity: 0.4;
        padding: 0;
        @include transition(0.3s);
        &:hover {
          color: $white;
          opacity: 1;
        }
      }
    }
  }

  .off-canvas-overlay {
    background-color: rgba($black, 0.8);
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  .btn-close {
    background-color: transparent;
    border: none;
    color: #ce1a2b;
    font-size: 24px;
    line-height: 1;
    opacity: 1;
    &:active,
    &:focus {
      border: none;
      box-shadow: none;
    }

    i {
      line-height: 1;
    }

    &:hover {
      color: $theme-color;
    }
  }

  .off-canvas-inner {
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    position: relative;
    transform: translateX(0);
    transition: 0.3s;
    height: 100vh;
    width: 100%;
    z-index: 3;

    .off-canvas-content {
      background-color: $black-111;
      height: 100%;
      padding: 0 10px;
      position: relative;
      overflow-y: auto;
      transition: 0.4s;
      transition-duration: 0.4s;
      transform: translateX(100%);
      width: 320px;
      z-index: 9;
      @media #{$xs-device} {
        width: 310px;
      }

      .off-canvas-item {
        margin-bottom: 30px;
        margin-top: 21px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .off-canvas-footer {
    .side-footer {
      display: inline-block;
      .widget-social-icons {
        display: block;
        a {
          border: none;
          color: #000;
          font-size: 16px;
          float: left;
          margin-right: 40px;
          padding: 0;
          @media #{$xs-device} {
            font-size: 13px;
            margin-right: 22px;
          }
          &.icon-color {
            color: #185381;
            &.color-twitter {
              color: #429cd6;
            }
            &.color-instagram {
              color: #521313;
            }
            &.color-googleplus {
              color: #d62d20;
            }
          }
        }
      }
      .widget-copyright {
        display: block;
        @media #{$xs-device} {
          display: inline-block;
          margin-top: 12px;
        }
        p {
          line-height: 1.7;
          @media #{$xs-device} {
            font-size: 15px;
          }
        }
      }
    }
  }
}
