/*
-----------------------------------------------------------------------
	Contact CSS
-----------------------------------------------------------------------
*/
.contact-area {
  padding: 90px 0 100px;
  position: relative;
  @media #{$md-device} {
    padding: 70px 0 80px;
  }
  @media #{$sm-device} {
    padding: 50px 0 60px;
  }
  .title {
    color: $black;
    font-size: 30px;
    letter-spacing: 0;
    font-family: $secondary-font;
    line-height: 46px;
    margin-bottom: 60px;
    @media #{$lg-device} {
      font-size: 24px;
      line-height: 38px;
      margin-bottom: 60px;
    }
    @media #{$md-device} {
      margin-bottom: 40px;
    }
    @media #{$sm-device} {
      font-size: 20px;
      margin-bottom: 40px;
    }
    @media #{$xxs-device} {
      font-size: 18px;
      line-height: 32px;
    }
  }
}

.contact-info-content {
  display: flex;
  justify-content: space-between;
  padding-bottom: 90px;
  @media #{$md-device} {
    margin: 0 -15px;
    padding-bottom: 70px;
  }
  @media #{$sm-device} {
    display: inline-block;
    padding-bottom: 20px;
    width: 100%;
  }
  @media #{$xxs-device} {
    margin: 0;
  }
  .info-item {
    max-width: 277px;
    min-width: 277px;
    @media #{$md-device} {
      min-width: auto;
      padding: 0 15px;
      width: 33.3333333333%;
    }
    @media #{$sm-device} {
      float: left;
      margin-bottom: 20px;
      width: 50%;
    }
    @media #{$xxs-device} {
      max-width: none;
      width: 100%;
    }
    .info-title {
      align-items: center;
      border-bottom: 1px solid #d3d6da;
      display: flex;
      justify-content: space-between;
      line-height: 1;
      margin-bottom: 22px;
      padding-bottom: 9px;
      h4 {
        color: $black;
        font-family: $font-current-theme1;
        font-size: 16px;
        font-weight: $font-weight-bold;
        letter-spacing: 0;
        line-height: 1;
        margin-bottom: 0;
        text-transform: uppercase;
      }
      .icon {
        color: $black;
        font-size: 20px;
      }
      &.info-item2 {
        h4 {
          position: relative;
          top: -2px;
        }
        .icon {
          font-size: 24px;
          position: relative;
          top: -4px;
        }
      }
    }
    .info-content {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 30px;
      font-family: $heading-font;
      @media #{$md-device} {
        font-size: 14px;
        line-height: 26px;
      }
      p {
        color: $black;
        margin-bottom: 0;
      }
      a {
        color: $black;
        display: inline-block;
        position: relative;
        &:after {
          background-color: #535353;
          bottom: 5px;
          content: "";
          height: 1px;
          right: 0;
          position: absolute;
          width: 0;
          @include transition(0.3s);
        }
        &:hover {
          color: $black;
          &:after {
            left: 0;
            width: 100%;
          }
        }
      }
    }
    &:last-child {
      @media #{$md-device} {
      }
    }
  }
}

.contact-form {
  .title {
    margin-bottom: 42px;
    @media #{$sm-device} {
      font-size: 24px;
      margin-bottom: 30px;
    }
    @media #{$xxs-device} {
      font-size: 22px;
    }
  }
}

.contact-map-area {
  background-color: $gray-light;
  height: 600px;
  @media #{$xxl-device} {
    height: 420px;
  }
  @media #{$lg-device} {
    height: 380px;
  }
  @media #{$md-device} {
    height: 320px;
  }
  @media #{$sm-device} {
    height: 300px;
  }
  iframe {
    height: 100%;
    width: 100%;
  }
}
