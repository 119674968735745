/*
-----------------------------------------------------------------------
  Footer CSS
-----------------------------------------------------------------------
*/

.footer-area {
  background-color: #ffffff;
  color: $body-color;
  padding: 60px 0 60px;
  // Responsive
  @media #{$md-device} {
    padding: 40px 0 40px;
  }
  @media #{$sm-device} {
    padding: 40px 0 40px;
  }
  .container-fluid {
    padding: 0 60px;
    @media #{$xxl-device} {
      padding: 0 15px;
    }
    @media #{$xxs-device} {
      padding: 0 15px;
    }
  }
  &.border-top-style {
    padding: 60px 0 60px;
    // Responsive
    @media #{$md-device} {
      padding: 40px 0 40px;
    }
    @media #{$sm-device} {
      padding: 40px 0 40px;
    }
    &:before {
      background-color: $gray-light;
      content: "";
      height: 1px;
      left: 60px;
      position: absolute;
      top: 0;
      width: calc(100% - 120px);
      @media #{$xxl-device} {
        left: 14px;
        width: calc(100% - 26px);
      }
      @media #{$lg-device} {
        left: 14px;
        width: calc(100% - 26px);
      }
      @media #{$md-device} {
        left: 14px;
        width: calc(100% - 26px);
      }
      @media #{$xxs-device} {
        left: 15px;
        width: calc(100% - 30px);
      }
    }
  }
  .widget-title {
  }
  .about-widget {
    .footer-logo {
      display: inline-block;
      @media #{$lg-device} {
        max-width: 150px;
      }
      @media #{$sm-device} {
        max-width: 130px;
      }
    }
    p {
      margin-bottom: 40px;
    }
  }
  .widget-contact-info {
    align-items: center;
    color: $black;
    display: flex;
    margin-bottom: 0;
    margin-top: -29px;
    li {
      color: $black;
      font-size: 16px;
      letter-spacing: -0.1px;
      padding: 0 8px;
      @media #{$xxs-device} {
        font-size: 14px;
      }
      a {
        color: $black;
        position: relative;
        &:after {
          background-color: #535353;
          bottom: 1px;
          content: "";
          height: 1px;
          right: 0;
          position: absolute;
          width: 0;
          @include transition(0.3s);
        }
        &:hover {
          color: $black;
          &:after {
            left: 0;
            width: 100%;
          }
        }
      }
      span {
        margin-left: 3px;
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        margin-right: 0;
        padding: 0;
      }
    }
  }
  .widget-copyright {
    margin-top: 29px;
    p {
      color: $black-999;
      font-size: 16px;
      letter-spacing: -0.1px;
      padding-top: 0;
      @media #{$xxs-device} {
        font-size: 13px;
      }
      span {
        color: $black;
        font-weight: $font-weight-bold;
      }
      i {
        color: $theme-color4;
        font-size: 16px;
        margin: 0 5px;
      }
      a {
        color: $black;
        font-weight: $font-weight-bold;
        letter-spacing: -0.15px;
      }
    }
  }
  .footer-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    @media #{$md-device} {
      display: block;
      text-align: center;
    }
    .widget-footer-nav {
      ul {
        margin-bottom: 0;
        @media #{$md-device} {
          margin-bottom: 30px;
        }
        li {
          display: inline-block;
          padding: 0 20px;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          a {
            display: block;
            font-size: 14px;
            line-height: 1.2;
            font-weight: 700;
            font-family: $heading-font;
            text-transform: uppercase;
            color: $heading-color;
            &:hover {
              color: $theme-color;
            }
          }
        }
      }
    }
    .widget-copyright {
      @media #{$md-device} {
        margin-bottom: 25px;
      }
    }
    .about-widget {
      .footer-logo {
        @media #{$md-device} {
          display: inline-block;
          margin-bottom: 0px;
        }
      }
    }
    .widget-social {
      margin-bottom: 0;
      li {
        display: inline-block;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        &.social-text {
          margin-right: 50px;
          // Responsive
          @media #{$lg-device} {
            margin-right: 25px;
          }
          span {
            font-size: 14px;
            font-weight: 700;
            line-height: 1.2;
            font-family: $heading-font;
            text-transform: uppercase;
            color: $heading-font;
          }
        }
        a {
          font-size: 18px;
          line-height: 1.3;
          color: $body-color;
          &:hover {
            color: $heading-color;
          }
        }
      }
    }
  }
}

.reveal-footer {
  bottom: 0;
  left: 0;
  position: relative;
  width: 100%;
  @media #{$md-device} {
    position: relative;
  }
}

.site-wrapper-reveal {
  position: relative;
  @media #{$md-device} {
    margin-bottom: 0 !important;
  }
}
