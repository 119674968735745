/*
-----------------------------------------------------------------------
	Home Slider CSS
-----------------------------------------------------------------------
*/

.home-slider-area {
  &.slider-photographer {
    .slider-content-area {
      text-align: center;
      margin: 90px 0 21px;
      @media #{$md-device} {
        margin: 70px 0 0;
      }
      @media #{$sm-device} {
        margin: 55px 0 0;
      }
      h2 {
        font-size: 30px;
        line-height: 1.6;
        font-family: $secondary-font;
        letter-spacing: -0.1px;
        margin-bottom: 0;
        @media #{$lg-device} {
          br {
            display: none;
          }
        }
        @media #{$md-device} {
          font-size: 24px;
        }
        @media #{$sm-device} {
          font-size: 21px;
        }
        @media #{$xs-device} {
          font-size: 16px;
        }
      }
    }
    .container {
      @media #{$sm-device} {
        max-width: none;
        padding: 0 30px;
      }
      @media #{$xs-device} {
        padding: 0 15px;
      }
    }
  }
}
