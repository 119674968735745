/*
-----------------------------------------------------------------------
	Team CSS
-----------------------------------------------------------------------
*/
.team-area {
  padding: 90px 0 60px;
  @media #{$md-device} {
    padding: 70px 0 40px;
  }
  @media #{$sm-device} {
    padding: 50px 0 20px;
  }
  .title {
    margin-bottom: 87px;
    @media #{$lg-device} {
      margin-bottom: 50px;
    }
    @media #{$md-device} {
      margin-bottom: 30px;
    }
  }
}

.team-member {
  margin-bottom: 30px;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  .thumb {
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .content {
    background-color: rgba($black, 0.1);
    height: 100%;
    left: 0;
    opacity: 0;
    padding: 0 25px;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;
    @include transition(0.4s);
    @media #{$md-device} {
      padding: 0 15px;
    }
    .member-info {
      bottom: 62px;
      position: absolute;
      .name {
        bottom: -15px;
        color: #fff;
        font-family: $font-current-theme1;
        font-size: 18px;
        font-weight: $font-weight-bold;
        letter-spacing: 0;
        margin-bottom: 1px;
        opacity: 0;
        position: relative;
        @include transition(0.5s);
        a {
          color: $white;
          &:hover {
            color: $theme-color11;
          }
        }
      }
      .designation {
        bottom: -10px;
        color: #fff;
        font-size: 14px;
        opacity: 0;
        position: relative;
        @include transition(0.5s);
      }
    }
    .member-icons {
      bottom: 14px;
      opacity: 0;
      position: absolute;
      transition: 0.5s;
      @include transition(0.5s);
      a {
        color: $white;
        font-size: 16px;
        margin-right: 13.3px;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          color: $white;
        }
      }
    }
  }
  &:hover {
    &:before {
      opacity: 0.5;
    }
    .content {
      opacity: 1;
      visibility: visible;
      .member-info {
        .name {
          bottom: 0;
          opacity: 1;
        }
        .designation {
          bottom: 0;
          opacity: 1;
          transition-delay: 0.2s;
        }
      }
      .member-icons {
        bottom: 19px;
        opacity: 1;
        transition-delay: 0.4s;
      }
    }
  }
  &.become-member {
    @media #{$xs-device} {
      margin-top: 25px;
      text-align: center;
    }
    @media #{$xxs-device} {
      margin-top: 15px;
    }
    .thumb {
      visibility: hidden;
      @media #{$xs-device} {
        display: none;
      }
    }
    .content {
      background-color: #fff;
      opacity: 1;
      visibility: visible;
      @media #{$xs-device} {
        position: relative;
      }
      .member-info {
        left: 49%;
        top: 52%;
        transform: translate(-50%, -50%);
        @media #{$md-device} {
          bottom: auto;
          top: 50%;
        }
        @media #{$xs-device} {
          left: auto;
          position: relative;
          top: auto;
          transform: none;
        }
        .name {
          bottom: 0;
          color: $black;
          font-family: $font-current-theme1;
          font-size: 18px;
          font-weight: $font-weight-bold;
          line-height: 20px;
          opacity: 1;
          text-transform: uppercase;
          @media #{$xs-device} {
            font-size: 20px;
          }
          @media #{$xxs-device} {
            font-size: 18px;
          }
          a {
            color: $black;
            line-height: 1.2;
            position: relative;
            @media #{$md-device} {
              br {
                display: none;
              }
            }
            &:hover {
              color: $black;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
