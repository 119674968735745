/*
-----------------------------------------------------------------------
	Blog CSS
-----------------------------------------------------------------------
*/

.blog-filter-menu {
  margin-bottom: 63px;
  @media #{$lg-device} {
    margin-bottom: 50px;
  }
  @media #{$md-device} {
    margin-bottom: 35px;
  }
  button {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: $black;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 1.1;
    margin-right: 38.74px;
    padding: 0;
    position: relative;
    @include transition(0.3s);
    @media #{$sm-device} {
      margin-right: 25px;
    }
    &:last-child {
      margin-right: 0;
    }
    &:after {
      background-color: #535353;
      bottom: 0;
      content: "";
      height: 1px;
      position: absolute;
      right: 0;
      width: 0;
      @include transition(0.3s);
    }
    &.is-checked,
    &:hover {
      &:after {
        left: 0;
        width: 100%;
      }
    }
  }
}

.blog-area {
  &.blog-masonry-area {
    padding: 0 0 100px;
    @media #{$md-device} {
      padding: 0 0 80px;
    }
    @media #{$sm-device} {
      padding: 0 0 60px;
    }
    .container {
      @media #{$sm-device} {
        max-width: none;
      }
    }
  }
}

.post-items-style1 {
  .post-item {
    margin-bottom: 51px;
    @media #{$xs-device} {
      margin-bottom: 30px;
    }
    .thumb {
      margin-bottom: 20px;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        @include transition(all 0.8s ease-in-out);
      }
      .overlay-content {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        .play-btn {
          background-color: $white;
          border-radius: 50%;
          color: $theme-color7;
          display: inline-block;
          font-size: 42px;
          height: 60px;
          left: 50%;
          line-height: 70px;
          position: absolute;
          text-align: center;
          transform: translate(-50%, -50%);
          top: 50%;
          width: 60px;
        }
        .quotes {
          left: 50%;
          padding: 0 38px;
          position: absolute;
          text-align: center;
          transform: translate(-50%, -50%);
          top: 50%;
          width: 100%;
          @media #{$lg-device} {
            padding: 0 15px;
          }
          .icon {
            color: $theme-color8;
            display: inline-block;
            font-size: 30px;
            line-height: 1;
            margin-bottom: 23px;
            transform: rotate(180deg);
            @media #{$sm-device} {
              font-size: 24px;
              margin-bottom: 12px;
            }
          }
          p {
            color: $black;
            font-family: $font-current-theme2;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0;
            margin-bottom: 36px;
            @media #{$lg-device} {
              font-size: 16px;
            }
            @media #{$sm-device} {
              font-size: 14px;
              line-height: 24px;
              margin-bottom: 15px;
            }
            @media #{$xs-device} {
              font-size: 20px;
              line-height: 36px;
            }
            @media #{$xxs-device} {
              font-size: 16px;
              line-height: 30px;
            }
          }
          .name {
            color: $black;
            font-weight: $font-weight-bold;
            letter-spacing: 0.25px;
            line-height: 1;
            padding-bottom: 10px;
            @media #{$sm-device} {
              padding-bottom: 5px;
            }
          }
        }
      }
    }
    .content {
      .title {
        color: $black;
        font-family: $font-current-theme1;
        font-size: 20px;
        font-weight: $font-weight-bold;
        line-height: 30px;
        letter-spacing: 0;
        margin-bottom: 22px;
        @media #{$xs-device} {
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 15px;
        }
        a {
          border-bottom: 1px solid transparent;
          color: $black;
          position: relative;
          &:hover {
            border-bottom-color: #535353;
          }
        }
      }
      .meta {
        color: $black-999;
        font-size: 13px;
        letter-spacing: -0.25px;
        margin-bottom: 8px;
        a {
          margin-right: 5px;
          color: $black-999;
          .icon {
            color: $body-color;
            padding-right: 7px;
          }
          &:hover {
            color: $black;
          }
        }
        span {
          padding: 0 6px;
        }
      }
      .btn-link {
        color: $black-999;
        font-size: 13px;
        font-weight: $font-weight-bold;
        line-height: 1;
        letter-spacing: 0.25px;
        text-transform: uppercase;
        &:hover {
          color: $black;
        }
      }
      &.post-text {
        padding-top: 20px;
      }
    }
    .post-audio {
      margin-bottom: 22px;
      position: relative;
      img {
        width: 100%;
      }
      .embed-responsive {
        height: 113px;
      }
    }
    &:hover {
      .thumb {
        img {
          @include scale(1.2);
        }
        &.post-video {
          img {
            transform: none;
          }
        }
      }
    }
  }
}

.blog-details-area {
  padding: 100px 0 100px;
  position: relative;
  @media #{$md-device} {
    padding: 80px 0 80px;
  }
  @media #{$sm-device} {
    margin: 0 30px;
    padding: 60px 0 60px;
  }
  @media #{$xs-device} {
    margin: 0 15px;
  }
  @media #{$xxs-device} {
    padding: 60px 0 80px;
  }
  .blog-navigation {
    color: $black;
    display: flex;
    font-size: 14px;
    font-weight: $font-weight-bold;
    justify-content: space-between;
    left: -12px;
    letter-spacing: 3.35px;
    position: absolute;
    right: -16px;
    text-transform: uppercase;
    top: 514px;
    @media #{$xlm-device} {
      left: -45px;
      right: -45px;
    }
    @media #{$xxl-device} {
      left: -100px;
      right: -100px;
      top: 385px;
    }
    @media #{$lg-device} {
      top: 300px;
    }
    @media #{$md-device} {
      top: 270px;
    }
    @media #{$sm-device} {
      left: 0;
      margin-top: 60px;
      position: relative;
      right: 0;
      top: auto;
    }
    @media #{$xs-device} {
      font-size: 13px;
      letter-spacing: 1px;
    }
    .prev {
      line-height: 1;
      transform: rotate(-90deg);
      @media #{$sm-device} {
        transform: none;
      }
    }
    .next {
      line-height: 1;
      transform: rotate(90deg);
      @media #{$sm-device} {
        transform: none;
      }
      a {
        i {
          margin-left: 9px;
        }
      }
    }
    a {
      color: $black;
      &:hover {
        color: $black-999;
      }
      i {
        margin-left: 13px;
      }
    }
  }
}

.post-details-content {
  background-color: $white;
  margin: 0 auto;
  max-width: 1310px;
  .post-details-body {
    margin: 0 auto;
    max-width: 860px;
    padding: 47px 15px 140px;
    @media #{$lg-device} {
      padding: 47px 15px 90px;
    }
    @media #{$md-device} {
      padding: 47px 15px 70px;
    }
    @media #{$xxs-device} {
      padding: 35px 15px 60px;
    }
    .content {
      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 47px;
      padding-bottom: 29px;
      .title {
        color: $black;
        font-family: $font-current-theme1;
        font-size: 30px;
        font-weight: $font-weight-bold;
        letter-spacing: -0.25px;
        margin-bottom: 24px;
        @media #{$md-device} {
          font-size: 28px;
        }
        @media #{$xs-device} {
          font-size: 24px;
        }
        @media #{$xxs-device} {
          font-size: 20px;
        }
      }
      .meta {
        color: $body-color;
        font-size: 14px;
        letter-spacing: 0;
        margin-bottom: 43px;
        @media #{$xs-device} {
          font-size: 13px;
          margin-bottom: 40px;
        }
        a {
          margin-right: 5px;
          color: $body-color;
          &:hover {
            color: $black;
            i {
              color: $black;
            }
          }
          &.author {
            i {
              font-size: 14px;
              top: -1px;
              @media #{$xs-device} {
                font-size: 12px;
              }
            }
          }
        }
        i {
          color: $black-999;
          font-size: 18px;
          margin-right: 9px;
          position: relative;
          top: 2px;
          @include transition(0.3s);
          @media #{$xs-device} {
            font-size: 14px;
          }
        }
        span {
          color: $gray-ccc;
          display: inline-block;
          margin: 0 18px;
          @include transition(0.3s);
          @media #{$sm-device} {
            margin: 0 6px;
          }
        }
      }
      p {
        color: $body-color;
        font-size: 14px;
        letter-spacing: 0.35px;
        line-height: 26px;
        margin-bottom: 16px;
        &.text-block {
          color: $black;
          font-weight: $font-weight-bold;
          margin-bottom: 26px;
        }
      }
      ul {
        color: $body-color;
        font-size: 14px;
        letter-spacing: 0.35px;
        line-height: 26px;
        margin-bottom: 16px;
        margin-left: 20px;
        li {
          i {
            color: $black-999;
            font-size: 12px;
            @include transition(0.3s);
          }
          &:hover {
            i {
              color: $body-color;
            }
          }
        }
      }
      h4 {
        font-size: 24px;
        letter-spacing: -0.25px;
        margin-bottom: 34px;
        margin-top: 69px;
        @media #{$sm-device} {
          font-size: 22px;
          margin-bottom: 25px;
          margin-top: 50px;
        }
      }
    }
    .widget-tags {
      color: $black-999;
      font-size: 14px;
      letter-spacing: 0.35px;
      line-height: 26px;
      margin-bottom: 21px;
      span {
        color: $black;
        font-weight: $font-weight-bold;
        margin-right: 6px;
        text-transform: uppercase;
      }
      a {
        margin-right: 5px;
        color: $body-color;
        &:hover {
          color: $black;
        }
      }
    }
    .social-icons {
      color: $black-999;
      font-size: 14px;
      letter-spacing: 0.35px;
      line-height: 1;
      margin-bottom: 51px;
      span {
        color: $black;
        font-weight: $font-weight-bold;
        margin-right: 16px;
        text-transform: uppercase;
      }
      a {
        color: $black-999;
        font-size: 16px;
        margin-right: 13.75px;
        &:hover {
          color: $black;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .comment-area {
      border-top: 1px solid #e5e5e5;
      padding-top: 58px;
    }
    .bread-crumbs {
      color: $black-999;
      font-size: 14px;
      letter-spacing: 0;
      margin-bottom: 67px;
      @media #{$md-device} {
        margin-bottom: 40px;
      }
      @media #{$xxs-device} {
        margin-bottom: 30px;
      }
      a {
        color: $black-999;
        &:hover {
          color: $black;
        }
      }
      span {
        padding: 0 8px;
        &.active {
          color: $black;
          padding: 0;
        }
      }
    }
  }
  .post-gallery {
    .gallery-slider {
      .swiper-slide {
        img {
          width: 100%;
        }
      }
    }
    .swiper-pagination {
      &.swiper-pagination-bullets {
        position: absolute;
        bottom: 29px !important;
        left: 240px !important;
        width: auto;
        @media #{$lg-device} {
          left: 100px !important;
        }
        @media #{$xs-device} {
          bottom: 20px !important;
          left: 50px !important;
        }
        @media #{$xxs-device} {
          left: 30px !important;
        }
        .swiper-pagination-bullet {
          background-color: transparent !important;
          border: 2px solid $white !important;
          height: 13px !important;
          margin: 0 8.13px !important;
          opacity: 1 !important;
          width: 13px !important;
          @include transition(0.3s);
          border-radius: 100%;
          &::before {
            background-color: transparent;
          }
          @media #{$xxs-device} {
            margin: 0 3px !important;
          }
          &:first-child {
            margin-left: 0 !important;
          }
          &:last-child {
            margin-right: 0 !important;
          }
          &.swiper-pagination-bullet-active,
          &:hover {
            background-color: $white !important;
          }
        }
      }
    }
  }
  .audio-details {
    .embed-responsive {
      iframe {
        position: static;
      }
    }
  }
  .blog-quote {
    text-align: center;
    padding: 50px 50px;
    background-color: #f0f6f6;
    // Responsive
    @media #{$xs-device} {
      padding: 30px 30px;
    }
    .overlay-content {
      .quotes {
        .icon {
          color: #cc9966;
          display: inline-block;
          font-size: 30px;
          line-height: 1;
          margin-bottom: 23px;
          transform: rotate(180deg);
        }
        p {
          color: #000;
          font-family: $secondary-font;
          max-width: 700px;
          margin: 0 auto;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: 0;
          margin-bottom: 36px;
        }
        .name {
          color: #000;
          font-weight: 700;
          letter-spacing: 0.25px;
          line-height: 1;
          padding-bottom: 10px;
        }
      }
    }
  }
}

.comment-area {
  .title {
    color: $black;
    font-family: $font-current-theme1;
    font-size: 24px;
    font-weight: $font-weight-bold;
    letter-spacing: -0.25px;
    margin-bottom: 33px;
  }
  .comment-content {
    margin-bottom: 49px;
    .single-comment {
      margin-bottom: 40px;
      .author-info {
        position: relative;
        .thumb {
          left: 0;
          position: absolute;
          top: 2px;
          @media #{$xxs-device} {
            position: relative;
            margin-bottom: 25px;
          }
          img {
            width: auto;
          }
        }
        .author-details {
          margin-left: 100px;
          border-top: 1px solid $gray-ccc;
          padding: 20px 0 0 21px;
          @media #{$xxs-device} {
            margin-left: 0;
            padding-left: 0;
          }
          ul {
            display: inline-block;
            margin-bottom: 10px;
            li {
              align-items: flex-end;
              display: flex;
              float: left;
              font-weight: $font-weight-bold;
              line-height: 1;
              min-height: 16px;
              &.name {
                color: $black;
                font-size: 16px;
                letter-spacing: -0.25px;
                span {
                  margin-left: 5px;
                  margin-right: 5px;
                }
              }
              &.meta-date {
                color: #aba5a5;
                font-size: 11px;
                font-weight: $font-weight-normal;
                letter-spacing: 0.35px;
                padding-bottom: 1px;
                text-transform: uppercase;
              }
            }
          }
          p {
            display: block;
            font-size: 13px;
            letter-spacing: 0.35px;
            line-height: 24px;
            margin-bottom: 11px;
          }
          .btn-reply {
            color: $black;
            font-family: $font-current-theme1;
            font-size: 13px;
            font-style: italic;
            font-weight: $font-weight-normal;
            position: relative;
            &:after {
              background-color: #535353;
              bottom: 1px;
              content: "";
              height: 1px;
              right: 0;
              position: absolute;
              width: 0;
              @include transition(0.3s);
            }
            &:hover {
              color: $black;
              &:after {
                left: 0;
                width: 100%;
              }
            }
          }
        }
      }
      &.last-child {
        margin-bottom: 0;
      }
    }
  }
  .comment-form-wrap {
    padding-top: 21px;
    iframe {
      height: auto !important;
    }
  }
}

.widget-item {
  .widget-title {
    color: $black;
    font-size: 18px;
    letter-spacing: 0;
    margin-bottom: 47px;
    text-transform: uppercase;
  }
}

.widget-tags {
  ul {
    li {
      color: $black-999;
      display: inline-block;
      font-size: 14px;
      font-weight: $font-weight-normal;
      margin: 0 6px 10px 0;
      a {
        background-color: $white-f3;
        border-radius: 0;
        color: $black-999;
        display: inline-block;
        font-size: 14px;
        font-weight: $font-weight-normal;
        letter-spacing: 0.148px;
        line-height: 1;
        padding: 7.5px 14px;
        &:hover {
          background-color: $theme-color;
          color: $white;
        }
      }
      &:nth-child(4) {
        a {
          padding: 7.5px 10px;
        }
      }
    }
  }
  &.tags-style2 {
    padding-top: 1px;
    ul {
      li {
        a {
          background-color: transparent;
          border: 1px solid #d7d7d7;
          letter-spacing: -0.15px;
          padding: 6.5px 12.41px;
          &:hover {
            background-color: $theme-color;
            border-color: $theme-color;
            color: $white;
          }
        }
      }
    }
  }
}

.widget-categories {
  ul {
    li {
      border-bottom: 1px solid #d3d8dc;
      color: $black;
      font-size: 14px;
      padding: 17px 0;
      letter-spacing: -0.093px;
      line-height: 1;
      margin-bottom: 0;
      text-transform: uppercase;
      a {
        color: $black;
        display: block;
        font-size: 14px;
        span {
          float: right;
        }
        &:hover {
          color: $theme-color;
        }
      }
      &:first-child {
        padding-top: 1px;
      }
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.widget-blog-post {
  ul {
    li {
      border-bottom: 1px solid #d3d8dc;
      display: flex;
      margin-bottom: 19px;
      padding-bottom: 19px;
      position: relative;
      .thumb {
        margin-right: 20px;
        min-width: 79px;
        img {
          border-radius: 0;
          width: auto;
        }
      }
      .content {
        position: relative;
        top: -5px;
        a {
          color: $black;
          display: block;
          font-size: 14px;
          font-weight: $font-weight-normal;
          letter-spacing: 0;

          &:hover {
            color: $theme-color;
          }
        }
        span {
          color: #aba5a5;
          display: block;
          font-size: 14px;
        }
      }
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.widget-search-box {
  form {
    input {
      background-color: $white-f5;
      border: none;
      box-shadow: none;
      height: 51px;
      padding: 10px 30px;
      width: 100%;
      @include placeholder {
        color: $black-999;
        font-size: 14px;
        letter-spacing: -0.15px;
      }
    }
  }
}

.sidebar-wrapper {
  &.blog-sidebar-wrapper {
    padding-left: 70px;
    padding-top: 3px;
    @media #{$lg-device} {
      padding-left: 15px;
    }
    @media #{$md-device} {
      padding-left: 0;
    }
    .widget-item {
      .widget-title {
        letter-spacing: 0.323px;
        margin-bottom: 37px;
      }

      .widget-search-box {
        margin-bottom: 71px;
      }

      .widget-categories {
        margin-bottom: 71px;
        ul {
          margin-bottom: 0;
        }
      }

      .widget-blog-post {
        margin-bottom: 77px;
        padding-top: 7px;
      }

      .widget-tags {
        &.tags-style2 {
          ul {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
