/* 05.05 Search Box CSS */
.main-search-active {
  background: rgba(0, 0, 0, 0.92) none repeat scroll 0 0;
  width: 100%;
  min-height: 100vh;
  padding: 32px 46px 39px;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  z-index: 9999;
  transition: all 0.5s ease-in-out;
  //Responsive
  @media #{$sm-device} {
    padding: 32px 20px 39px;
  }
}
.sidebar-search-icon {
  position: absolute;
  top: 50px;
  right: 70px;
  color: #ffffff;
  font-size: 60px;
  cursor: pointer;
  //Responsive
  @media #{$sm-device} {
    right: 0;
  }
  & button {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    color: #fff;
    cursor: pointer;
    font-size: 50px;
    line-height: 1;
    padding: 0;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
}
.sidebar-search-input {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  display: inline-block;
  padding: 0 50px;
  width: 75%;
  text-align: center;
  font-size: 18px;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.3s;
  //Responsive
  @media #{$xl-device} {
    padding: 0 10px;
  }
  @media #{$lg-up-device} {
    padding: 0 10px;
  }
  @media #{$md-device} {
    padding: 0 10px;
  }
  @media #{$sm-device} {
    padding: 0 10px;
    width: 100%;
  }
  & form .form-search {
    position: relative;
    & input {
      padding: 10px 0;
      border: 0;
      border-radius: 0;
      border-bottom: 5px solid transparent;
      background-color: transparent;
      font-size: 200px;
      width: 100%;
      height: auto;
      color: $white;
      transform: scale3d(0, 1, 1);
      transform-origin: 0 50%;
      transition: transform 0.3s;
      //Responsive
      @media #{$xl-device} {
        font-size: 150px;
      }
      @media #{$lg-up-device} {
        font-size: 120px;
      }
      @media #{$md-device} {
        font-size: 80px;
      }
      @media #{$sm-device} {
        font-size: 50px;
      }
      &::placeholder,
      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &::placeholder {
        color: $white;
      }
    }
    & button {
      background-color: transparent;
      border: medium none;
      color: $white;
      font-size: 30px;
      padding: 0;
      position: absolute;
      right: 0;
      bottom: 10px;
      cursor: pointer;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
    }
  }
  & p {
    font-size: 90%;
    font-weight: bold;
    display: block;
    padding: 11px 0;
    text-align: right;
    opacity: 0;
    line-height: 1.6;
    color: $white;
    transform: translate3d(0, 50px, 0);
    transition: opacity 0.4s, transform 0.4s;
    //Responsive
    @media #{$sm-device} {
      font-size: 14px;
    }
  }
}
.main-search-active.inside {
  opacity: 1;
  visibility: visible;
  z-index: 9999999;
  & .sidebar-search-input {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -50%) scale(1);
    & input {
      transform: scale3d(1, 1, 1);
      transition: 0.5s ease-out 0.3s;
      border-bottom-color: $white;
    }
    & p {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}
