/*
-----------------------------------------------------------------------
	About CSS
-----------------------------------------------------------------------
*/
.about-page-wrapper {
  position: relative;
  .title {
    font-family: $font-current-theme1;
    font-size: 36px;
    font-weight: $font-weight-bold;
    letter-spacing: -0.15px;
    margin-bottom: 40px;
    @media #{$lg-device} {
      font-size: 30px;
    }
    @media #{$sm-device} {
      font-size: 24px;
    }
  }
}

.service-area {
  padding: 95px 0 0px;
  @media #{$md-device} {
    padding: 75px 0 0;
  }
  @media #{$sm-device} {
    padding: 55px 0 0;
  }
  .title {
    margin-bottom: 40px;
    @media #{$lg-device} {
      margin-bottom: 20px;
    }
  }
  p {
    font-size: 18px;
    letter-spacing: -0.16px;
    line-height: 30px;
    margin-bottom: 0;
    @media #{$sm-device} {
      font-size: 16px;
      br {
        display: none;
      }
    }
    @media #{$xs-device} {
      font-size: 14px;
      line-height: 24px;
    }
  }
  ul {
    font-size: 18px;
    letter-spacing: -0.16px;
    line-height: 30px;
    margin-bottom: 0;
    @media #{$xs-device} {
      font-size: 16px;
    }
    li {
      margin-bottom: 0;
      a {
        color: $body-color;
        &:hover {
          color: $black;
        }
      }
    }
  }
}

.award-area {
  background-color: $white-f1;
  padding: 95px 0 40px;
  @media #{$md-device} {
    padding: 75px 0 25px;
  }
  @media #{$sm-device} {
    padding: 55px 0 10px;
  }
  .title {
    margin-bottom: 83px;
    @media #{$lg-device} {
      margin-bottom: 60px;
    }
    @media #{$xs-device} {
      margin-bottom: 40px;
    }
  }
}

.award-content {
  .award-item {
    margin-bottom: 60px;
    position: relative;
    @media #{$lg-device} {
      margin-bottom: 75px;
    }
    @media #{$lg-device} {
      margin-bottom: 55px;
    }
    .award-year {
      color: $black;
      font-size: 18px;
      left: 0;
      letter-spacing: 0;
      position: absolute;
      @media #{$xxs-device} {
        margin-bottom: 35px;
        position: relative;
      }
    }
    .content {
      padding-left: 303px;
      @media #{$lg-device} {
        padding-left: 180px;
      }
      @media #{$md-device} {
        padding-left: 130px;
      }
      @media #{$xs-device} {
        padding-left: 110px;
      }
      @media #{$xxs-device} {
        padding-left: 0;
      }
      .item {
        margin-bottom: 38px;
        @media #{$xs-device} {
          margin-bottom: 25px;
        }
        p {
          color: $black;
          font-size: 18px;
          font-weight: $font-weight-bold;
          letter-spacing: 0;
          margin-bottom: 7px;
          @media #{$xs-device} {
            font-size: 16px;
          }
        }
        h4 {
          color: $gray-ccc;
          font-family: $font-current-theme1;
          font-size: 36px;
          font-weight: $font-weight-bold;
          letter-spacing: 0;
          @media #{$lg-device} {
            font-size: 24px;
          }
          @media #{$xs-device} {
            font-size: 20px;
          }
          span {
            color: $black;
            position: relative;
            @media #{$xs-device} {
              line-height: 1.6;
            }
            &:after {
              background-color: $black;
              bottom: 3px;
              content: "";
              height: 2px;
              right: 0;
              position: absolute;
              width: 0;
              @include transition(0.3s);
            }
          }
        }
        &:hover {
          h4 {
            span {
              &:after {
                left: 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
